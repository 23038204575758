import { useEffect, useRef, useState } from "react";
import "./escrowedRequestBuyerPops.scss";
import { PAYMENT_METHOD_TYPES, useEscrowedRequest } from "../escrowedRequestProvider";
import { AlertTriangle, Check, FileUp, Info, Loader2, X } from "lucide-react";
import ReactSelect from "react-select";
import { formatAmount } from "../../../../common/formatAmount";
import cn from "classnames";
import { useAuth } from "../../../../providers/AuthProvider";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import _ from "lodash";
import api from "../../../../api";
import axios from "axios";
import { reactSelectStyle } from "../../../../common/reactselectstyles/reactselectstyles";
import Big from "big.js";

const emptyEscrowedRequestBuyerPopsState = {
	paymentMethods: [
		{
			label: "",
			options: [],
		},
	],
	selectedPaymentMethods: [],
	uploadingPop: false,
	uploadProgress: 0,
	uploadStarted: false,
	uploadSuccessful: false,
};

const UPLOAD_POP_FORM_FIELDS = {
	receiptAmount: "receiptAmount",
};

const EscrowedRequestBuyerPops = () => {
	const { authTokens } = useAuth();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const [escrowedRequestBuyerPopsState, setEscrowedRequestBuyerPopsState] = useState(emptyEscrowedRequestBuyerPopsState);
	const [fileToUpload, setFileToUpload] = useState({});
	const { incomingBuyerPops, outgoingBuyerPops, activeRequest, refreshActiveRequest, updatingPops, rejectPop, verifyPop } = useEscrowedRequest();
	const uploadPopDialogRef = useRef(null);
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [],
		},
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (!_.isEmpty(acceptedFiles)) {
				const file = {
					acceptedFile: acceptedFiles[0],
					name: acceptedFiles[0].name,
					preview: URL.createObjectURL(acceptedFiles[0]),
				};
				setFileToUpload(file);
			}
		},
	});
	const domain = api.getApiDomain();

	const updateFileUrls = (pops) => {
		return pops.map((pop) => ({
			...pop,
			fileUrl: `${domain}/orders/${pop.orderId}/requests/${pop.requestId}/proofofpayments/${pop.id}/files/${pop.file}?token=${authTokens.IdToken}`,
		}));
	};
	const updatedIncomingBuyerPops = updateFileUrls(incomingBuyerPops);
	const updatedOutgoingBuyerPops = updateFileUrls(outgoingBuyerPops);


	// Setup buyer's payment methods (owner of request)
	useEffect(() => {
		const paymentMethods = activeRequest.paymentMethods;
		const PaymentMethodsOptions = {};
		paymentMethods.forEach((paymentMethod) => {
			switch (paymentMethod.type) {
				case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
					PaymentMethodsOptions["Bank"] = PaymentMethodsOptions["Bank"] || {
						label: "Bank",
						options: [],
					};
					PaymentMethodsOptions["Bank"].options.push({
						label: `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`,
						value: paymentMethod,
					});
					break;
				case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
					PaymentMethodsOptions["Phonenumber"] = PaymentMethodsOptions["Phonenumber"] || { label: "Phonenumber", options: [] };
					PaymentMethodsOptions["Phonenumber"].options.push({
						label: `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`,
						value: paymentMethod,
					});
					break;
				case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
					PaymentMethodsOptions["Wallet Address"] = PaymentMethodsOptions["Wallet Address"] || { label: "Wallet Address", options: [] };
					PaymentMethodsOptions["Wallet Address"].options.push({
						label: `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`,
						value: paymentMethod,
					});
					break;
			}
		});

		const escrowedRequestBuyerPopsStatePaymentMethods = [];
		Object.keys(PaymentMethodsOptions).forEach((key) => {
			const paymentMethodOption = PaymentMethodsOptions[key];
			escrowedRequestBuyerPopsStatePaymentMethods.push(paymentMethodOption);
		});

		updateEscrowedRequestBuyerPopsStates([{ key: "paymentMethods", value: escrowedRequestBuyerPopsStatePaymentMethods }]);
	}, []);

	const viewPaymentMethod = (paymentMethod) => {
		switch (paymentMethod.type) {
			case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
				return `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`;
			case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
				return `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`;
			case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
				return `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`;
		}
	};

	const updateEscrowedRequestBuyerPopsState = ({ key, value }) => {
		const newState = { ...escrowedRequestBuyerPopsState };
		newState[key] = value;
		setEscrowedRequestBuyerPopsState(newState);
	};

	const updateEscrowedRequestBuyerPopsStates = (states) => {
		const newState = { ...escrowedRequestBuyerPopsState };
		states.forEach(({ key, value }) => {
			newState[key] = value;
		});
		setEscrowedRequestBuyerPopsState(newState);
	};

	const startUploadPop = () => {
		uploadPopDialogRef.current.showModal();
	};

	const closeUploadPop = () => {
		uploadPopDialogRef.current.close();
	};

	const getUploadPopLabel = () => {
		let label = "Drag & Drop or Browse the file";
		if (!_.isEmpty(fileToUpload.name)) {
			label = fileToUpload.name;
		}
		return label;
	};

	const setMaxAmount = () => {
		const amount = activeRequest.escrowSellBalance;
		setValue(UPLOAD_POP_FORM_FIELDS.receiptAmount, amount);
	};

	const confirmUploadPop = async (popForm) => {
		const amount = popForm.receiptAmount;
		const paymentMethod = escrowedRequestBuyerPopsState.selectedPaymentMethods;

		updateEscrowedRequestBuyerPopsStates([
			{ key: "uploadStarted", value: true },
			{ key: "uploadingPop", value: true },
		]);

		await uploadFile({ amount, paymentMethod });
	};

	const uploadFile = async ({ amount, paymentMethod }) => {
		const domain = api.getApiDomain();
		const fileForm = new FormData();
		fileForm.append("paymentMethod", JSON.stringify(paymentMethod.value));
		fileForm.append("amount", amount);
		fileForm.append("currency", paymentMethod.value.currency);
		fileForm.append("fileName", fileToUpload.name);
		fileForm.append("file", fileToUpload.acceptedFile);
		fileForm.append("popType", "ESCROW_SELLER_POP");
		const token = authTokens.IdToken;
		const orderId = activeRequest.order.id;
		const requestId = activeRequest.id;

		const result = await axios.post(`${domain}/orders/${orderId}/requests/${requestId}/proofofpayment`, fileForm, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			onUploadProgress: (progress) => {},
		});

		updateEscrowedRequestBuyerPopsStates([
			{ key: "uploadStarted", value: true },
			{ key: "uploadingPop", value: false },
			{ key: "uploadSuccessful", value: true },
		]);

		refreshActiveRequest();

		setTimeout(() => {
			closeUploadPop();
			//TO DO : Clear upload memory and Close upload pop form
			updateEscrowedRequestBuyerPopsStates([
				{ key: "uploadStarted", value: false },
				{ key: "uploadingPop", value: false },
				{ key: "uploadSuccessful", value: false },
			]);
		}, 3000);
	};

	return (
		<div id="escrowed_request_buyer_pops_component">
			{/* OUTGOING POPS */}
			<div className="pops">
				<div className="pop_list_header">
					<div className="label">Outgoing Buyer PoPs</div>
					<div className="upload_pop" data-testid="escrowbuyerpop-upload" onClick={startUploadPop}>
						Upload
					</div>
					<dialog className="upload_pop_dialog_container" data-testid= "escrowpop-upload-dialog" ref={uploadPopDialogRef}>
						<div className="upload_pop_dialog_header">
							<div className="label">Upload Proof of Payment</div>
							<X className="close" onClick={closeUploadPop} />
						</div>
						<div className="upload_pop_dialog_body">
							<div className="upload_pop_dialog_details">
								<div className="amount_container">
									<div className="label">Bal Amount</div>
									<div className="amount">
										{formatAmount(activeRequest.escrowSellBalance)} {activeRequest.escrowSellBalanceCurrency}
									</div>
								</div>

								<div className="payment_methods">
									<div className="header"> Payment methods</div>
									<div data-testid="escrowbuyerpop-selectpaymentmethod">
									<ReactSelect
										styles={reactSelectStyle}
										closeMenuOnSelect={false}
										value={escrowedRequestBuyerPopsState.selectedPaymentMethods}
										options={escrowedRequestBuyerPopsState.paymentMethods}
										onChange={(evt) => {
											updateEscrowedRequestBuyerPopsState({
												key: "selectedPaymentMethods",
												value: evt,
											});
										}}
									/>
									</div>
									
									<div className="deposit_amounts"></div>
								</div>

								<div className="upload_btn_container">
									<div className="label">Upload POP picture</div>
									<div {...getRootProps({ className: "dropzone upload_btn" })} title={getUploadPopLabel()}>
										<input data-testid="escrowbuyerpop-uploadpopfile" {...getInputProps()} />
										<div className="label">{getUploadPopLabel()}</div>
										<FileUp className="icon" />
									</div>

									{escrowedRequestBuyerPopsState.uploadStarted && (
										<div className="uploading_progress_bar_container">
											<div className="uploading_progress_bar" style={{ width: `${escrowedRequestBuyerPopsState.uploadProgress}%` }}></div>
										</div>
									)}
								</div>

								<div className="receipt_amount_container">
									Enter Amount on the receipt
									<input
										className="receipt_amount"
										type="number"
										{...register("receiptAmount", {
											required: "Please fill in receipt amount",
											validate: {
												positive: (value) => parseFloat(value) > 0 || "Receipt amount must be greater than 0",
												notExceedBalance: (value) => {
													const balanceAmt = `${formatAmount(activeRequest.escrowSellBalance)} ${activeRequest.escrowSellBalanceCurrency}`;
													const pass = Big(value).lte(Big(activeRequest.escrowSellBalance));
													return pass || `Receipt amount must not exceed balance ( ${balanceAmt} )`;
												},
											},
										})}
									/>
									{errors.receiptAmount && (
										<span className="receipt_amount_input_error">
											<Info className="receipt_amount_input_error_icon" />
											{errors.receiptAmount.message}
										</span>
									)}
									<div className="subtitle">
										<div className="max_amount_subtitle">Set to Bal Amount</div>
										<div className="max_control" data-testid ="escrowbuyerpop-setmaxamount" onClick={setMaxAmount}>
											ALL
										</div>
									</div>
								</div>

								{!escrowedRequestBuyerPopsState.uploadStarted && (
									<div className="upload_pop_controls">
										<div className="upload_pop_control cancel" onClick={closeUploadPop}>
											Cancel
										</div>
										<div className="upload_pop_control confirm" data-testid="escrowbuyerpop-submitpop" onClick={handleSubmit(confirmUploadPop)}>
											Submit PoP
										</div>
									</div>
								)}

								{escrowedRequestBuyerPopsState.uploadStarted && (
									<div className="upload_status">
										{escrowedRequestBuyerPopsState.uploadingPop && <Loader2 className="uploading_pop_loader" />}
										{!escrowedRequestBuyerPopsState.uploadingPop && escrowedRequestBuyerPopsState.uploadSuccessful && <Check />}
										{!escrowedRequestBuyerPopsState.uploadingPop && !escrowedRequestBuyerPopsState.uploadSuccessful && (
											<div className="upload_error">
												<AlertTriangle /> Error uploading POP.. please try again later
											</div>
										)}
									</div>
								)}
							</div>

							{!_.isEmpty(fileToUpload.preview) && (
								<div className="image_preview">
									<div className="header">Image Preview</div>
									<div className="image_container">
										<img
											src={fileToUpload.preview}
											// Revoke data uri after image is loaded
											onLoad={() => {
												URL.revokeObjectURL(fileToUpload.preview);
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</dialog>
				</div>
				<div className="pop_list outgoing">
					{updatedOutgoingBuyerPops.length == 0 && <div className="no_pop">No document yet..</div>}
					{updatedOutgoingBuyerPops.map((pop) => (
						<div className={cn("pop", { verified: pop.popState == "VERIFIED" })} key={pop.id}>
							<div className="image_preview_container">
							<img src={pop.fileUrl} alt="" />
							</div>
							<div className="details_container">
								<div className="pop_line_1">
									<div className="pop_amount">
										{formatAmount(pop.amount)} {pop.currency}
									</div>
									{pop.popState == "PENDING" && <div className="pop_status pending">pending</div>}
									{pop.popState == "VERIFIED" && <div className="pop_status verified">verified</div>}
									{pop.popState == "REJECTED" && <div className="pop_status rejected">rejected</div>}
								</div>
								<div className="pop_line_2">
									<div className="pop_payment_method_label">Paid to</div>
									<div className="pop_payment_method">{viewPaymentMethod(pop.paymentMethod)}</div>
								</div>
							</div>
							<div className="more_container">
								<svg
									className="detailsIcon lucide lucide-ellipsis-vertical more_action"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round">
									<circle cx="12" cy="12" r="1" />
									<circle cx="12" cy="5" r="1" />
									<circle cx="12" cy="19" r="1" />
								</svg>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="spacer">
				<hr />
			</div>

			{/* INCOMING POPS */}
			<div className="pops">
				<div className="pop_list_header">
					<div className="label">Incoming Buyer PoPs</div>
				</div>
				<div className="pop_list incoming">
					{updatedIncomingBuyerPops.length == 0 && <div className="no_pop">No document yet..</div>}

					{updatedIncomingBuyerPops.map((pop) => (
						<div className="pop_container" key={pop.id}>
							<div className="pop">
								<div className="image_preview_container">
								<img src={pop.fileUrl} alt="" />
								</div>
								<div className="details_container">
									<div className="pop_line_1">
										<div className="pop_amount">
											{formatAmount(pop.amount)} {pop.currency}
										</div>
										{pop.popState == "PENDING" && <div className="pop_status pending">pending</div>}
										{pop.popState == "VERIFIED" && <div className="pop_status verified">verified</div>}
										{pop.popState == "REJECTED" && <div className="pop_status rejected">rejected</div>}
									</div>
									<div className="pop_line_2">
										<div className="pop_payment_method_label">Paid to</div>
										<div className="pop_payment_method">{viewPaymentMethod(pop.paymentMethod)}</div>
									</div>
								</div>
								<div className="more_container">
									<svg
										className="detailsIcon lucide lucide-ellipsis-vertical more_action"
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round">
										<circle cx="12" cy="12" r="1" />
										<circle cx="12" cy="5" r="1" />
										<circle cx="12" cy="19" r="1" />
									</svg>
								</div>
							</div>
							{updatingPops.includes(pop.id) && (
								<div className="pop_actions_loader_container">
									<Loader2 className="pop_actions_loader" />
								</div>
							)}

							{!updatingPops.includes(pop.id) && pop.popState == "PENDING" && (
								<div className="pop_actions">
									<div
										className="reject"
										onClick={() => {
											rejectPop(pop);
										}}>
										Reject
									</div>
									<div
									    data-testid="escrowbuyerpop-verify"
										className="verify"
										onClick={() => {
											verifyPop(pop);
										}}>
										Verify
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default EscrowedRequestBuyerPops;
